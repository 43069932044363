
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500&display=swap');
html{
    overflow-x: hidden;
    max-width: 100vw;
}
body {
    overflow-x: hidden;
    max-width: 100vw;
    margin: 0;
    padding: 0;
    font-family: poppins;
    * {
        font-family: "poppins";
        
    }
}

