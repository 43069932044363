@import '../../../globals/variables.scss';
 
.header { 
    position: absolute; 
    left: 0;
    top: 0;
    right: 0;
    height: auto;
    background-color: white;
    z-index:1;
    border-bottom: 1px solid lightgrey;
    img{
        max-width: 100%; 
        @media screen and (max-width: 992px) {
            max-width: 120px;
        }
    }  
}

.innerHeader {  
    margin: 0 auto; 
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 10px 0px;
    .portalUserInfo{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .profileImgContainer{
            border: 1px solid grey;
            border-radius: 50%;
            margin-right: 15px; 
            width: 40px;
            height: 40px;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
                transform:translateY(5px)
            }
        }
    }
    .logo{
        display: flex;
        align-items: center;
        justify-content: center;;
        a{
            display: flex;
        }
        img{
            margin-right: 40px;
            width: 120px;;
            object-fit: contain;
            max-height: 40px;  
        }
    } 
} 