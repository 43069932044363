@import '../../globals/variables.scss';

@media screen and (max-width: 992px) {
    .affordability {
        margin: 0 0;
        border-bottom: 2px solid $primary;
        .affordabilityDetails {
            background-color: whitesmoke;
            h4 {
                font-size: 20px;
                padding: 15px;
                background-color: lightgray;
                margin: 0;
            }
            .affordabilityDetailsInner {
                display: flex;
                padding: 15px;
                padding-bottom: 20px;
                flex-direction: column;

                span {
                    display: inline-block;
                    min-width: 40%;
                    color: black;
                    font-weight: 400;
                }

                > div {
                    width: 100%;
                    p {
                        font-weight: 600;
                        display: block;
                        font-size: 14px;
                        margin: 10px 0 0;
                        // color: grey;
                    }
                    .address {
                        display: flex;
                        span {
                            display: block;
                            &:last-of-type {
                                width: auto;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
            .affordabilityDetailsCase {
                padding-bottom: 50px;
                border-bottom: 1px solid lightgrey;
                &:last-of-type {
                    padding-bottom: 0;
                    border-bottom: none;
                }
                .affordabilityDetailsInner span {
                    min-width: 45%;
                }
            }
        }
    }
}
