@import '../../globals/variables.scss';
@media screen and (max-width: 992px) {
    .halfSection {
        margin: 0px 0 30px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        &.flipContent {
            flex-direction: column;
        }
        > div {
            width: 100%;
            &:first-of-type {
                width: 100%;
            }
            &.content {
                // display: flex;
                // align-items: center;
                margin-bottom: 20px;
            }
            h3 {
                margin: 0 0 30px;
                color: #333;
                font-size: 22px;
                border-bottom: 1px solid $primary;
                padding: 10px 0;
                font-weight: 400;
            }
            p {
                letter-spacing: 1px;
                line-height: 2.2;
                color: grey;
            }
        }
        img {
            max-width: 100%;
            min-height: 300px;
            object-fit: cover;
            margin: 20px 0;
        }
    }
}
