@import '../../globals/variables.scss';
.videoContainer {
    margin: 50px 0 100px;
    text-align: center;
    h2 {
        font-size: 32px;
        font-weight: 400;
        letter-spacing: 1px;
        position: relative;
        display: block;
        padding-bottom: 30px;
        margin-bottom: 30px;
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 150px;
            height: 3px;
            background-color: $primary;
        }
    }
    .videoContainerInner {
        margin-bottom: 20px;
        max-width: 100%;
        border: 7px solid $primary;
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        align-items: center;
        video {
            max-width: 100%;
        }
    }
}
