@import '../../../globals/variables.scss';
@media screen and (max-width: 992px) {
   .footer {
       background-color: whitesmoke;
       padding: 30px 0;
       color: black;

        .innerFooter {
            width: 100%;
            max-width: $wrapperMaxWidth;
            margin: 0 auto; 
            p {    
                a {   
                    display: inline-block;
                    margin-right: 20px; 
                } 
            }
       } 
   }
}