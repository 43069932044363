@import '../../globals/variables.scss';

@media screen and (max-width: 992px) {
    .banner {
        padding: 85px 0 15px;
        &:before {
            content: '';
            background-color: rgba(0, 0, 0, 0.5);
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            top: 0;
        }
        > div {
            position: relative;
            padding-top: 18px;
            h1 {
                line-height: 1.2;
                font-size: 26px;
            }
            h1,
            p {
                margin: 0 0 15px;
                font-weight: 300;
            }
        }
    }
}
