@import '../../globals/variables.scss';
.halfSection {
    margin: 50px 0 100px;
    display: flex;
    justify-content: space-between;
    &.flipContent {
        flex-direction: row-reverse;
    }
    > div {
        width: 48%;
        &:first-of-type {
            width: 43%;
        }
        &.content {
            // display: flex;
            // align-items: center;
        }
        h3 {
            margin: 0 0 30px;
            color: #333;
            font-size: 28px;
            border-bottom: 1px solid $primary;
            padding: 10px 0;
            font-weight: 400;
        }
        p {
            letter-spacing: 1px;
            line-height: 2.2;
            color: grey;
        }
    }
    img {
        max-width: 100%;
        min-height: 500px;
        object-fit: cover;
    }
}
