@import '../../../globals/variables.scss';
 
.stepContainer {   
    border-bottom: 1px solid lightgrey;
    // padding: 15px 0;
    .stepTitle{
        padding: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span{
        display: flex; 
        align-items: center;

        }
    }
    &.containerOpen{
        .stepTitle{
            background-color: $primary;
            color: white;
            padding: 10px 15px; 
        }
    }
    &.containerComplete{
        .stepTitle{
            background-color: $primary;
            color: white;
            padding: 10px 15px; 
        } 
    }
    h3{
        margin: 0;
        cursor: pointer;
    }
    .stepMain{
        height:0;
        overflow: hidden;
        &.stepOpened{
            height: auto;

        }
    }
}
 