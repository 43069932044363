@import '../../../globals/variables.scss';
 
.stepContainer {   
    border-bottom: 1px solid black;
    padding: 15px 0;
    h3{
        margin: 0;
        cursor: pointer;
    }
    .stepMain{
        height:0;
        overflow: hidden;
        &.stepOpened{
            height: auto;
        }
    }    
}
.buttonContainer{
    margin: 20px auto;
    display: block;
    text-align: center;
}

p.lenderBold{
    display: block;
    // text-align: center;
    // font-size: 20px;
    margin: 20px 0;
    span{
        font-weight: 400
    }
}
p.previewButton{
    display: block;
    text-align: center;
    span{ 
        font-weight: 400;
        cursor: pointer;
        display: inline-block;
        margin: 0 auto;
        color: grey;
        font-size: 13px;
        letter-spacing: 0.5px;
        &:hover{
            text-decoration: underline;
        }
    }

}

.sigPadContainer {
    border: 1px solid lightgrey;
    border-radius: 2px;;
    margin-bottom: 30px;
    max-width: 800px;
    margin: 0 auto;
    position: relative;
    span{
        position: absolute;
        right: 0;
        background-color: $primary;
        color: white;
        display: block;
        bottom: 0;
        padding: 10px 20px;
        font-size: 12px;
        border-top-left-radius: 4px;
        cursor: pointer;
    }
    

    p{
        background-color: $primary;
        padding: 10px;
        text-align: center;
        color: white;
        margin: 0;
        &.previewButton{
            text-align: center;
            font-weight: 800;
        }
    }
    img{
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    
    }
    >div{
        position: relative;
        height: 300px;
        width: 100%;
    }
}