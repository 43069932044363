@import '../../globals/variables.scss';
.openLetterContent {
    .previewButton {
        display: block;
        margin: 20px auto 0;
        text-align: center;
        span {
            cursor: pointer;
            font-weight: 600;
            letter-spacing: 1px;
            color: lightgrey;
        }
    }
    form {
        max-width: 800px;
        margin: 0 auto 50px;
        iframe {
            width: 100%;
        }
        .names {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            fieldset {
                width: 48%;
            }
        }
        fieldset {
            width: 100%;
            border: none;
            padding: 0;
            margin: 0 0 15px 0;
            box-sizing: content-box;
            max-width: 100%;
            label {
                max-width: 100%;
                display: block;
                font-size: 12px;
                width: 100%;
                font-weight: 600;
                letter-spacing: 1px;
            }
            input {
                margin-top: 3px;
                width: 100%;
                display: block;
                padding: 0;
                box-sizing: border-box;
                padding: 4px 10px;
                border: 1px solid lightgrey;
                border-radius: 4px;
            }
        }
    }
}
