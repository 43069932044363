@import '../../globals/variables.scss';
 
.dualSigContainer {   
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    width: 100%;

    @media screen and (max-width: 800px) {
        padding: 0;
    }
}

.previews{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 50px 0;

  
    > * {
        border: 2px solid rgb(50, 54, 57);;
        border-radius: 8px;
        overflow: hidden;
        width: 100%;
        height: 600px;

    }
      @media screen and (max-width: 992px) {
        flex-direction: column;
        padding: 25px 0;
        justify-content: center;
        align-items: center;
        > * {
            margin-bottom:20px;
            border: 2px solid rgb(50, 54, 57);;
            border-radius: 8px;
            overflow: hidden;
            width: 100%;

        }
    }

}

.buttonContainer{
    margin: 20px auto;
    display: block;
    text-align: center;
}
 

.sigPadContainer {
    border: 1px solid lightgrey;
    border-radius: 2px;;
    margin-bottom: 30px;
    max-width: 800px;
    margin: 0 auto;
    position: relative;
    span{
        position: absolute;
        right: 0;
        background-color: $primary;
        color: white;
        display: block;
        bottom: 0;
        padding: 10px 20px;
        font-size: 12px;
        border-top-left-radius: 4px;
        cursor: pointer;
    }
    

    p{
        background-color: $primary;
        padding: 10px;
        text-align: center;
        color: white;
        margin: 0;
        &.previewButton{
            text-align: center;
            font-weight: 800;
        }
    }
    img{
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    
    }
    >div{
        position: relative;
        height: 300px;
        width: 100%;
    }
}