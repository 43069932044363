@import '../../globals/variables.scss';

.pocSidebar {
    width: 240px;
    min-height: 400px;
    background-color: whitesmoke;
    border-right: 8px solid $primary;

    @media screen and (max-width: 768px) {
        background-color: #f5f5f5;
        border-bottom: 8px solid #c78e5f;
        min-height: 0;
        width: 100%;
        border-right: none;
    }
    ul {
        @media screen and (max-width: 768px) {
            margin-bottom: 0;
        }
        list-style-type: none;
        padding-left: 0;
        margin-top: 0;
        li {
            cursor: pointer;
            padding: 10px;
            border-bottom: 1px solid lightgrey;
            font-size: 12px !important;
            display: flex;
            flex-direction: column;
            &.selected {
                background-color: $primary;
                color: white;
            }
            span {
                font-size: 15px;
                line-height: 1;
            }
        }
    }
}
