@import '../../../globals/variables.scss';

.inputDateStyle {
    width: 100%;
    max-width: 100%;
    display: block;
    line-height: 1.6;
    font-size: 16px;
    box-sizing: border-box;
    padding: 5px;
    font-size: 17px;
    border-radius: 5px;
    border: 1px solid #d6d6d6;
    @media screen and (max-width: 992px) {
        width: 100%;
        display: block;
        max-width: 100%;
        box-sizing: border-box;
    }
}

span.notice {
    display: block;
    color: red;
    font-size: 11px;
}
