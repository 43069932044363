@import '../../globals/variables.scss';

.explainationContent {
}

.idUploadOA {
    form {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 15px 0;
        label {
            position: relative;
            display: block;
            height: 230px;
            width: 350px;
            background: whitesmoke;
            border-radius: 4px;
            margin-bottom: 15px;
            cursor: pointer;
            border: 1px solid lightgrey;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                right: 0;
                bottom: 0;
                object-fit: cover;
                max-width: 40%;
                transform: translate(-50%, -50%);
                max-height: 90%;
            }
        }
        input {
            visibility: hidden;
            opacity: 0;
            width: 1px;
            height: 1px;
        }
    }
}
