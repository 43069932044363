.pageContent {
    padding: 0;
    margin: 0;
    > div {
        // padding-top: 90px;
    }
    p,
    ul li,
    a {
        font-size: 17px;
        @media screen and (max-width: 992px) {
            font-size: 15px;
        }
    }
}

.foaV2Container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    > iframe {
        display: block;
        width: 100%;
        max-width: 1200px;
        max-height: 800px;
        margin: 50px 0;
    }
}
.part36Container,
.notPart36Container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    > iframe {
        display: block;
        width: 100%;
        max-width: 1200px;
        max-height: 800px;
        margin: 150px 0;
    }
}
.Ex160Container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    > embed {
        display: block;
        width: 100%;
        max-width: 800px;
        margin: 50px 0;
    }
}
.underMaintance {
    z-index: 9999999999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    h1 {
        color: black;
        text-align: center;
        margin: 0;
        font-weight: 100;
    }
}
.pocContent {
    hr {
        margin-bottom: 0;
        background: green;
        border: 1px solid whitesmoke;
    }
}
.desktopStyle {
    display: block !important;

    @media screen and (max-width: 768px) {
        display: none !important;
    }
}

.desktopStyleFlex {
    display: flex !important;

    @media screen and (max-width: 768px) {
        display: none !important;
    }
}

.mobileStyle {
    display: none !important;

    @media screen and (max-width: 768px) {
        display: block !important;
    }
}

.mobileStyleFlex {
    display: none !important;

    @media screen and (max-width: 768px) {
        display: flex !important;
    }
}

.extendDatePicker {
    > * {
        width: 100%;
    }
}
