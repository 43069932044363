@import '../../globals/variables.scss';
.affordability {
    margin: 30px 0;
    border-bottom: 2px solid $primary;
    .affordabilityDetails {
        background-color: whitesmoke;
        h4 {
            font-size: 20px;
            padding: 15px;
            background-color: lightgray;
            margin: 0;
        }
        .affordabilityDetailsInner {
            display: flex;
            padding: 15px;
            padding-bottom: 20px;

            span {
                display: inline-block;
                min-width: 120px;
                color: black;
                font-weight: 400;
            }

            > div {
                width: 50%;
                p {
                    font-weight: 600;

                    font-size: 16px;
                    margin: 10px 0 0;
                    // color: grey;
                }
                .address {
                    display: flex;
                    span {
                        display: block;
                        &:last-of-type {
                            width: auto;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
        .affordabilityDetailsCase {
            padding-bottom: 50px;
            border-bottom: 1px solid lightgrey;
            &:last-of-type {
                padding-bottom: 0;
                border-bottom: none;
            }
            .affordabilityDetailsInner span {
                min-width: 150px;
            }
        }
    }
}
